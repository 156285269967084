.asc-cms-content {
  ul {
    @extend .govuk-list;
    @extend .govuk-list--bullet;
  }

  ol {
    @extend .govuk-list;
    @extend .govuk-list--number;
  }

  table {
    @extend .govuk-table;

    tr {
      @extend .govuk-table__row;
    }
    th {
      @extend .govuk-table__header;
    }
    td {
      @extend .govuk-table__cell;
    }
  }
}

.asc-cms-downloads-content {
  @extend .asc-cms-content;
  a {
    display: flex;

    img {
      padding-right: 5px;
    }
  }

}