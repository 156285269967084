.govuk-util__float-right {
  float: right;
}

.govuk-util__align-right {
  text-align: right;
}

.govuk-util__float-left {
  float: left;
}

.govuk-util__align-left {
  text-align: left;
}

.govuk-util__align-center {
  text-align: center;
}

.govuk-util__align-justify {
  text-align: justify;
}

.govuk-util__light-bg {
  background: govuk-colour('light-grey');
}

.govuk-util__relative-top-1 {
  position: relative;
  top: 5px;
}

.govuk-util__bold {
  font-weight: bold;
  .govuk-list {
    font-weight: bold;
  }
}

.govuk-util__normal {
  font-weight: normal;
}

.govuk-util__font-style-normal {
  font-style: normal;
}

.govuk-util__inline-block {
  display: inline-block;
}

.govuk-util__block {
  display: block;
}

.govuk-util__list-record {
  border-bottom: solid 1px govuk-colour('mid-grey');
  padding: govuk-spacing(4) 0;

  &:first-child {
    border-top: solid 1px govuk-colour('mid-grey');
  }
}

.govuk-util__no-focus {
  outline: 0;
}

.govuk__flex {
  display: flex;
}

.govuk__flex-stack {
  display: flex;
  flex-direction: column;
  @media screen and (min-width: 641px) {
    flex-direction: row;
  }
}

.govuk__align-items-unset {
  align-items: unset;
}

.govuk__align-items-flex-start {
  align-items: flex-start;
}

.govuk__align-items-center {
  align-items: center;
}

.govuk__align-items-flex-end {
  align-items: flex-end;
}

.govuk__justify-content-space-between {
  justify-content: space-between;
}
.govuk__justify-content-end {
  justify-content: flex-end;
}

.govuk__nowrap {
  white-space: nowrap;
}
.govuk__nowrap-responsive {
  @media screen and (min-width: 641px) {
    white-space: nowrap;
  }
}

.govuk-util__vertical-align-top {
  vertical-align: top;
}

.govuk-util__vertical-align-middle {
  vertical-align: middle;
}

.govuk-util__vertical-align-bottom {
  vertical-align: bottom;
}

.govuk-line-height__normal {
  line-height: normal;
}
.govuk-max-width {
  max-width: calc(100vh - 274px);
}
.govuk__width-fit-content {
  width: fit-content;
}

.asc-font-19 {
  @include govuk-font($size: 19);
}

.asc-icon-align {
  position: relative;
  top: 4px;
}

.asc__preline {
  white-space: pre-line;
}

.asc__negative-margin-right-4 {
  margin-right: -1 * govuk-spacing(4);
}

.asc__vertical-line {
  border-left: 1px solid govuk-colour('light-grey');
}

.asc__hr_under_heading {
  margin-top: -15px;
  color: govuk-colour('light-grey');
  background-color: govuk-colour('light-grey');
  border: none;
  height: 2px;
}
